import * as api from '@/api/api'
import * as types from './types'
import * as mutations from '../mutations/types'
import Notification from '@/utils/notification'
import { router } from '@/router/router'
import { exitFullscreen } from '@/utils/common'

const updateNow = ({ commit }, now) => {
  commit(mutations.MUTATE_NOW, now)
}

const setAccount = ({ commit }, body) => {
  commit(mutations.MUTATE_ACCOUNT, body)
}

const logout = async ({ commit }) => {
  try {
    const { account, _to } = await api.logout()
    localStorage.setItem('account', account.id)
    commit(mutations.MUTATE_ACCOUNT, account)
    location.replace(_to)
  } catch {}
}

const getAccount = async ({ commit }) => {
  try {
    const { account, vendor } = await api.getAccount()
    commit(mutations.MUTATE_ACCOUNT, account)
    commit(mutations.MUTATE_VENDOR, vendor)
  } catch {}
}

const register = async (_, body) => {
  try {
    const { _to } = await api.register(body)
    setTimeout(() => location.replace(_to), 3 * 1000)
  } catch {}
}

const login = async ({ commit, dispatch }, { token, body = new FormData() }) => {
  try {
    commit(mutations.MUTATE_USERNAME, body.get('username'))

    const { account, _to } = await api.login(token, body)
    localStorage.setItem('account', account.id)

    setAccount({ commit }, account)
    router.push(_to).catch(() => {})
  } catch {}
}

const reset = async (_, body) => {
  try {
    const { _to } = await api.resetPassword(body)
    router.push(_to).catch(() => {})
  } catch {}
}

const createNotification = ({ dispatch, commit }, notification) => {
  if (!notification.msg) { return }

  dispatch(types.DELETE_NOTIFICATIONS)
  commit(
    mutations.MUTATE_NOTIFICATIONS,
    [new Notification(notification, () => dispatch(types.DELETE_NOTIFICATION))]
  )
}

const createNotifications = ({ dispatch, commit }, notifications) => {
  if (!notifications.length) { return }
  dispatch(types.DELETE_NOTIFICATIONS)
  commit(
    mutations.MUTATE_NOTIFICATIONS,
    notifications
      .filter(notification => notification.msg)
      .map(notification => {
        return new Notification(notification, () => dispatch(types.DELETE_NOTIFICATION))
      })
  )
}

const deleteNotification = ({ state, commit }, notification) => {
  const notifications = state.notifications
  if (notifications.length && notification?.msg) {
    const idx = notifications.findIndex(n => n.msg === notification.msg)

    if (idx !== -1) {
      notifications[idx].destroy()
      notifications.splice(idx, 1)
      commit(mutations.MUTATE_NOTIFICATIONS, notifications)
    }
  }
}

const deleteNotifications = ({ state, commit }) => {
  if (state.notification) {
    state.notifications.forEach(notification => {
      notification.destroy()
    })
    commit(mutations.MUTATE_NOTIFICATIONS)
  }
}

const updateCountNewMessages = ({ commit }, val) => {
  if (val > 0) {
    commit(mutations.MUTATE_COUNT_NEW_MESSAGES, val)
  }
}

const getSessions = async ({ commit }, silent) => {
  const { sessions, now } = await api.getSessions(silent)

  commit(mutations.MUTATE_SESSIONS, sessions || [])
  commit(mutations.MUTATE_NOW, now)
}

const continueSession = async ({ commit }, sessionId) => {
  const { session, step } = await api.continueSession(sessionId)
  commit(mutations.MUTATE_SESSIONS_ITEM, session)

  return { session, step }
}

const startSession = async ({ commit }, { sessionId, data }) => {
  const { session, step } = await api.startSession(sessionId, data)
  commit(mutations.MUTATE_SESSIONS_ITEM, session)

  return { session, step }
}

const toggleSteps = ({ commit }, value = false) => {
  localStorage.setItem('isOpenSteps', Boolean(value))
  commit(mutations.MUTATE_TOGGLE_STEPS, Boolean(value))
}

const toggleView = ({ commit }, value = false) => {
  localStorage.setItem('isFullView', Boolean(value))
  commit(mutations.MUTATE_TOGGLE_VIEW, Boolean(value))
}

const updateStep = ({ commit, state }, payload) => {
  const {
    permissions = state.permissions,
    max_filesize: maxFilesize = state.maxFilesize,
    now = state.now,
    step = state.step,
    lesson = state.lesson,
    quiz = state.quiz,
    essay = state.essay,
    lecture = state.lecture,
    session = state.session
  } = payload
  // Обновим данные шага в списке шагов сессии (возможно они не обновились сервере)
  const currentStep = (session?.steps || []).find(_step => _step.id === step?.id)
  if (currentStep) {
    Object.assign(currentStep, step, {
      // Поля курса обновлять не будем, так как они могут указывать на родительский курс, а не на текущий
      course_id: currentStep.course_id,
      course_uuid: currentStep.course_uuid,
      course_title: currentStep.course_title
    })
  }

  commit(mutations.MUTATE_PERMISSIONS, permissions)
  commit(mutations.MUTATE_MAX_FILESIZE, maxFilesize)
  commit(mutations.MUTATE_NOW, now)
  commit(mutations.MUTATE_STEP, step)
  commit(mutations.MUTATE_LESSON, lesson)
  commit(mutations.MUTATE_QUIZ, quiz)
  commit(mutations.MUTATE_ESSAY, essay)
  commit(mutations.MUTATE_LECTURE, lecture)
  commit(mutations.MUTATE_SESSION, session)
}

const saveQuizQuestion = async ({ commit }, opts) => {
  const { question, user_answer: userAnswer } = await api.saveQuestion(opts.quizId, opts.questionId, opts.data)
  commit(mutations.SAVE_QUIZ_QUESTION, { question, userAnswer })

  return question
}

const saveScorm = async ({ commit, state }, { stepID, grades, accepted }) => {
  try {
    const data = new FormData()
    data.append('grade', grades.grade)
    data.append('raw_grade', grades.raw || 0)
    data.append('min_grade', grades.min || 0)
    data.append('max_grade', grades.max || 0)
    data.append('status', (accepted && 'accepted') || 'rejected')
    const response = await api.updateScorm(stepID, data)

    if (response.step?.id) {
      const { session } = state
      const currentStep = (session?.steps || []).find(_step => _step.id === response.step?.id)
      if (currentStep) {
        // Обновим данные шага в списке шагов сессии (возможно они не обновились сервере)
        Object.assign(currentStep, response.step)
        commit(mutations.MUTATE_SESSION, session)
      }

      const { currentRoute } = router
      if (response.step.id === parseInt(currentRoute.params.stepID)) {
        commit(mutations.MUTATE_PERMISSIONS, response.permissions)
        commit(mutations.MUTATE_NOW, response.now)
        commit(mutations.MUTATE_STEP, response.step)
        commit(mutations.MUTATE_LESSON, response.lesson)

        exitFullscreen()
      }
    } else {
      // Что-то пошло не так, перезагрузим текущую страницу.
      location.reload()
    }

    return response
  } catch {}
}

const getSurveys = async ({ commit }, silent) => {
  const { surveys, now } = await api.getSurveys(silent)

  commit(mutations.MUTATE_SURVEYS, surveys || [])
  commit(mutations.MUTATE_NOW, now)
}

const continueSurvey = async ({ commit }, surveyId) => {
  const { survey, step } = await api.continueSurveys(surveyId)
  commit(mutations.MUTATE_SURVEYS_ITEM, survey)

  return { survey, step }
}

const startSurvey = async ({ commit }, data) => {
  const { survey } = await api.startSurvey(data)
  commit(mutations.MUTATE_SURVEYS_ITEM, survey)

  return { survey }
}

const getMeetings = async ({ commit }, silent) => {
  const { meetings, now } = await api.getMeetings(silent)
  commit(mutations.MUTATE_NOW, now)
  commit(mutations.MUTATE_MEETINGS, meetings || [])
}

const getVendorLimits = async ({ commit }, vendorId) => {
  const { limits = {} } = await api.getVendorLimits(vendorId)
  commit(mutations.MUTATE_VENDOR_LIMITS, limits)
}

export default {
  [types.UPDATE_NOW]: updateNow,
  [types.SET_ACCOUNT]: setAccount,
  [types.LOGOUT]: logout,
  [types.GET_ACCOUNT]: getAccount,
  [types.REGISTER]: register,
  [types.LOGIN]: login,
  [types.RESET_PASSWORD]: reset,
  [types.CREATE_NOTIFICATION]: createNotification,
  [types.CREATE_NOTIFICATIONS]: createNotifications,
  [types.DELETE_NOTIFICATIONS]: deleteNotifications,
  [types.DELETE_NOTIFICATION]: deleteNotification,
  [types.UPDATE_COUNT_NEW_MESSAGES]: updateCountNewMessages,
  [types.GET_SESSIONS]: getSessions,
  [types.CONTINUE_SESSION]: continueSession,
  [types.START_SESSION]: startSession,
  [types.TOGGLE_STEPS]: toggleSteps,
  [types.TOGGLE_VIEW]: toggleView,
  [types.UPDATE_STEP]: updateStep,
  [types.SAVE_QUIZ_QUESTION]: saveQuizQuestion,
  [types.SAVE_SCORM]: saveScorm,
  [types.GET_MEETINGS]: getMeetings,
  [types.GET_VENDOR_LIMITS]: getVendorLimits,
  [types.GET_SURVEYS]: getSurveys,
  [types.CONTINUE_SURVEY]: continueSurvey,
  [types.START_SURVEY]: startSurvey,
}
