import { request } from '@/utils/request.js'
import { getParamFrom } from '@/utils/common.js'

interface RequestBody {
  [key: string]: any
}

/* Показать текущий аккаунт с вендором домена */
export const getAccount = () => request('/account')

/* Регистрация аккаунта */
export const register = (body: RequestBody) => request(`/account/register${getParamFrom()}`, { method: 'POST', body })

/* Попробовать войти в систему */
export const login = (token = '', body: RequestBody) => request(`/account/login/${token}${getParamFrom()}`, { method: 'POST', body })

/* Выйти из системы */
export const logout = () => request('/account/logout', { method: 'POST' })

/* Восстановить пароль */
export const resetPassword = (body: RequestBody) => request('/account/reset', { method: 'POST', body })

/* Показать основные данные пользователя */
export const getUser = (account: number) => request(`/users/${account}`)

/* Обновить данные пользователя */
export const updateUser = (account: number, body: RequestBody) => request(`/users/${account}`, { method: 'POST', body })

/* Показать дополнительные настройки пользователя */
export const getUserSettings = (account: number) => request(`/users/${account}/settings`)

/* Показать зачетку пользователя */
export const getUserBook = (account: number) => request(`/users/${account}/book`)

/* Показать основные лимиты вендора */
export const getVendorLimits = (vendor = 0) => request(`/vendors/${vendor}/limits`)

/* Показать настройки брендирования вендора */
export const getVendorBranding = (vendor = 0) => request(`/vendors/${vendor}/branding`)

/* Показать список групп */
export const getGroups = () => request('/groups')

/* Показать список вебинаров */
export const getMeetings = (silent = false) => request('/meetings', {}, silent)

/* Показать вебинар */
export const getMeeting = (meeting: number, silent = false, profileCheck = true) => request(`/meetings/${meeting}`, {}, silent, profileCheck)

/* Показать список доступных чатов */
export const getChats = () => request('/messages/chats')

/* Удалить чат */
export const deleteChat = (chat: number, body: RequestBody) => request(`/messages/chats/${chat}/delete`, { method: 'POST', body })

/* Прочитать чат */
export const readChat = (chat: number) => request(`/messages/chats/${chat}/read`, { method: 'POST' })

/* Показать сообщения чата */
export const getMessages = (chat: number) => request(`/messages/chats/${chat}/messages`)

/* Отправить сообщение */
export const sendMessage = (chat: number, message: number, body: RequestBody) => request(`/messages/chats/${chat}/messages/${message}`, { method: 'POST', body })

/* Удалить сообщение */
export const deleteMessage = (chat: number, message: number, body: RequestBody) => request(`/messages/chats/${chat}/messages/${message}/delete`, { method: 'POST', body })

/* Получить курсы обучения, доступные для создания чатов/сообщений */
export const getAvailableCourses = () => request('/messages/chats/0/available-courses')

/* Получить количество новых/непрочитанных сообщений */
export const getNewMessagesCount = () => request('/messages/count/new', {}, true)

/* Получить данные курса */
export const getCourse = (course: number) => request(`/courses/${course}`)

/* Показать список программ обучения */
export const getSessions = (silent = false) => request('/training/sessions', {}, silent)

/* Начать программу обучения */
export const startSession = (session: number, body: RequestBody, silent = false, profileCheck = true) => request(`/training/sessions/${session}/start`, { method: 'POST', body }, silent, profileCheck)

/* Продолжить программу обучения */
export const continueSession = (session: number, silent = false, profileCheck = true) => request(`/training/sessions/${session}/continue`, { method: 'POST' }, silent, profileCheck)

/* Принять/закрыть учебную сессию */
export const acceptSession = (session: number) => request(`/training/sessions/${session}/accept`, { method: 'POST' })

/* Показать программу обучения */
export const getSession = (session: number, silent = false, profileCheck = true) => request(`/training/sessions/${session}`, {}, silent, profileCheck)

/* Показать документы прикрепленные к программе обучения */
export const getSessionDocuments = (session: number) => request(`/training/sessions/${session}/documents`)

/* Показать документы прикрепленные к программе обучения */
export const getSessionTextbooks = (session: number, silent = true) => request(`/training/sessions/${session}/textbooks`, {}, silent)

/* Показать шаг программы обучения */
export const getStep = (session: number, step: number) => request(`/training/sessions/${session}/steps/${step}`)

/* Показать шаги программы обучения */
export const getSteps = (session: number) => request(`/training/sessions/${session}/steps`)

/* Показать попытку шага */
export const getStepAttempt = (session: number, step: number, attempt: number) => request(`/training/sessions/${session}/steps/${step}/attempts/${attempt}`)

/* Перейти к следующему шагу обучения */
export const getNextStep = (session: number, step: number, method = 'GET') => request(`/training/sessions/${session}/steps/${step}/next`, { method })

/* Вернуться к предыдущему шагу обучения */
export const getPreviousStep = (session: number, step: number) => request(`/training/sessions/${session}/steps/${step}/previous`)

/* Пропустить обучение по шагу */
export const skipStep = (session: number, step: number, body: RequestBody) => request(`/training/sessions/${session}/steps/${step}/skip`, { method: 'POST', body })

/* Сохранить баллы, статус, оценку шага программы обучения. */
export const updateStep = (session: number, step: number, body: RequestBody) => request(`/training/sessions/${session}/steps/${step}`, { method: 'POST', body })

/* Показать все попытки пройти практические уроки */
export const getEssays = () => request('/training/essays')

/* Показать практический урок */
export const getEssay = (essay: number) => request(`/training/essays/${essay}`)

/* Начать попытку выполнить практический урок */
export const startEssay = (body: RequestBody) => request('/training/essays/0/start', { method: 'POST', body })

/* Остановить попытку выполнить практический урок */
export const stopEssay = (essay: number, body: RequestBody) => request(`/training/essays/${essay}/stop`, { method: 'POST', body })

/* Сохранить ответ на практический урок */
export const updateEssay = (essay: number, body: RequestBody) => request(`/training/essays/${essay}`, { method: 'POST', body })

/* Показать все попытки пройти тестирование */
export const getQuizzes = () => request('/training/quizzes')

/* Показать попытку тестирования */
export const getQuiz = (quiz: number) => request(`/training/quizzes/${quiz}`)

/* Начать попытку тестирования */
export const startQuiz = (body: RequestBody) => request('/training/quizzes/0/start', { method: 'POST', body })

/* Остановить попытку тестирования */
export const stopQuiz = (quiz: number, body: RequestBody) => request(`/training/quizzes/${quiz}/stop`, { method: 'POST', body })

/* Принять результаты попытки тестирования */
export const acceptQuiz = (quiz: number, body: RequestBody) => request(`/training/quizzes/${quiz}/accept`, { method: 'POST', body })

/* Изменить попытку тестирования */
export const updateQuiz = (quiz: number, body: RequestBody) => request(`/training/quizzes/${quiz}`, { method: 'POST', body })

/* Сохранить ответ на вопрос в тестировании */
export const saveQuestion = (quiz: number, question: number, body: RequestBody) => request(`/training/quizzes/${quiz}/questions/${question}`, { method: 'POST', body })

/* Показать лекцию */
export const getLecture = (step: number) => request(`/training/lectures/${step}`)

/* Показать скорм урок */
export const getScorm = (step: number) => request(`/training/scorms/${step}`)

/* Сохранить результат интерактивного урока прямо в шаг учебной сессии. */
export const updateScorm = (step: number, body: RequestBody) => request(`/training/scorms/${step}`, { method: 'POST', body })

/* Получить данные юридического лица по ИНН */
export const getLookupProfileCompany = (body: RequestBody) => request('/profiles/companies', { method: 'POST', body })

/* Получить данные банка по БИК */
export const getLookupProfileBank = (body: RequestBody) => request('/profiles/banks', { method: 'POST', body })

/* Показать список опросов */
export const getSurveys = () => request('/training/surveys')

/* Показать опрос */
export const getSurvey = (survey: number, silent = false, profileCheck = true) => request(`/training/surveys/${survey}`, {}, silent, profileCheck)

/* Начать опрос */
export const startSurvey = (body: RequestBody, silent = false, profileCheck = true) => request('/training/surveys/0/start', { method: 'POST', body }, silent, profileCheck)

/* Продолжить опрос */
export const continueSurveys = (surveys: number, silent = false, profileCheck = true) => request(`/training/surveys/${surveys}/continue`, { method: 'POST' }, silent, profileCheck)

/* Отправить опрос */
export const postSurvey = (survey: number, body: RequestBody) => request(`/training/surveys/${survey}`, { method: 'POST', body })
